import Styled from 'styled-components/macro';

const Headline = Styled.h1`
  font-size: 6rem;
  line-height: 7rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 0 0 1.5rem 0;
`;

export default Headline;
