import * as React from "react"
import Styled from 'styled-components/macro';

import Meta from '../components/meta'
import Headline from '../components/headline'
import Description from '../components/description'
import Layout from '../components/layout'
import Content from '../components/content'
import Container from '../components/container'

const TitleExtended = Styled(Headline)`
  font-size: 5rem;
  line-height: 6rem;
`;

const ContainerExtended = Styled(Container)`
    padding: 120px 0;
`;

const Text = Styled.div`
  max-width: 40rem;
  margin-right: 6rem;
`;


const NotFoundPage = ({ }) => (
    <Layout>
        <Meta
            title="The Best Design Software"
            description="The best design tools for all your needs"
            url="/"
        />
        <ContainerExtended purple>
            <Content>
                    <Text>
                        <TitleExtended>404</TitleExtended>
                        <Description>
                            This page doesn't exist! 😱
                        </Description>
                    </Text>
            </Content>
        </ContainerExtended>
    </Layout>
  )

  export default NotFoundPage;