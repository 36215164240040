import Styled from 'styled-components/macro';

const Description = Styled.p`
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: -0.2px;
  margin: 0 0 1.5rem 0;
`;

export default Description;
